import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

function ProductShowcase() {
  const desktopImages = [
    'https://storage.googleapis.com/find-me-sales-bucket/front-end/carousel/product-leadsearch.webp',
    'https://storage.googleapis.com/find-me-sales-bucket/front-end/carousel/product-peoplesearch.webp',
    'https://storage.googleapis.com/find-me-sales-bucket/front-end/carousel/product-leads.webp',
  ];

  const mobileImages = [
    'https://storage.googleapis.com/find-me-sales-bucket/front-end/carousel/product-flows.webp',
    'https://storage.googleapis.com/find-me-sales-bucket/front-end/carousel/product-action-table.webp',
    'https://storage.googleapis.com/find-me-sales-bucket/front-end/carousel/product-email.webp',
  ];

  const [currentDesktopIndex, setCurrentDesktopIndex] = useState(0);
  const [currentMobileIndex, setCurrentMobileIndex] = useState(0);

  useEffect(() => {
    const desktopInterval = setInterval(() => {
      setCurrentDesktopIndex((prevIndex) => (prevIndex + 1) % desktopImages.length);
    }, 4000);

    const mobileTimeout = setTimeout(() => {
      const mobileInterval = setInterval(() => {
        setCurrentMobileIndex((prevIndex) => (prevIndex + 1) % mobileImages.length);
      }, 4000);

      return () => clearInterval(mobileInterval);
    }, 2000);

    return () => {
      clearInterval(desktopInterval);
      clearTimeout(mobileTimeout);
    };
  }, [desktopImages.length, mobileImages.length]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // Align items at the top
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
      }}
    >
      {/* Desktop image container */}
      <Box
        sx={{
          position: 'relative',
          width: '90%', // Adjust width relative to the viewport
          maxWidth: '1000px', // Ensure it doesn't exceed the original size
          height: 'auto', // Maintain aspect ratio
          aspectRatio: '5 / 3', // For consistent scaling
          border: '2px solid #d3d3d3', // Light grey border
          borderBottom: 'none', // Remove the border from the bottom
          borderRadius: '23px 23px 0 0', // Rounded top corners only
          overflow: 'hidden',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        }}
      >
        {/* Desktop image */}
        <img
          src={desktopImages[currentDesktopIndex]}
          alt={`desktop-screen-${currentDesktopIndex}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'top', // Anchor image to the top
            transition: 'opacity 1s ease-in-out',
            opacity: 1,
          }}
        />
        {/* Gradient overlay for desktop */}
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0))',
            zIndex: 1,
          }}
        ></Box>
      </Box>
  
      {/* Mobile/iPhone-style overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '80%', sm: '64%' }, // Adjust for responsive placement
          left: { xs: '86%', sm: '86%', md: '86%', lg: '80%', xl: '75%' }, // Ensure alignment on small and wide screens
          transform: 'translate(-50%, -50%)',
          width: { xs: '24vw', sm: '20vw' }, // Shrink with viewport
          maxWidth: '220px', // Cap the size for larger screens
          height: 'auto', // Maintain aspect ratio
          aspectRatio: '2 / 4', // Consistent scaling
          border: '2px solid #d3d3d3', // Light grey border
          borderBottom: 'none', // Remove the border from the bottom
          borderRadius: '18px 18px 0 0', // Rounded top corners only
          overflow: 'hidden',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
          zIndex: 2,
          backgroundColor: '#fff',
          padding: '8px 8px 0 8px',
        }}
      >
        {/* Mobile image */}
        <img
          src={mobileImages[currentMobileIndex]}
          alt={`mobile-screen-${currentMobileIndex}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'top', // Anchor image to the top
            transition: 'opacity 1s ease-in-out',
            opacity: 1,
          }}
        />
        {/* Gradient overlay for mobile */}
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0))',
            zIndex: 1,
          }}
        ></Box>
      </Box>
    </Box>
  );
  
}

export default ProductShowcase;
